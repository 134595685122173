import { OrbitControls, CameraShake } from '@react-three/drei'
import { useControls } from 'leva'
import { Particles } from './Particles'

export default function App() {
  const queryParams = new URLSearchParams(window.location.search)
  const props = useControls({
    focus: { value: queryParams.get('f') !== null ? queryParams.get('f') : 5.91, min: 3, max: 7, step: 0.01 },
    speed: { value: queryParams.get('s') !== null ? queryParams.get('f') :20, min: 0.1, max: 100, step: 0.1 },
    aperture: { value: queryParams.get('a') !== null ? queryParams.get('f') :4.8, min: 1, max: 5.6, step: 0.1 },
    fov: { value: queryParams.get('o') !== null ? queryParams.get('f') :50, min: 0, max: 200 },
    curl: { value: queryParams.get('c') !== null ? queryParams.get('f') :0.27, min: 0.01, max: 0.5, step: 0.01 }
  })
  
  return (
    <>
      <OrbitControls makeDefault autoRotate autoRotateSpeed={2.5} zoomSpeed={0.5} />
      <CameraShake yawFrequency={1} maxYaw={0.01} pitchFrequency={0.1} maxPitch={0.05} rollFrequency={0.01} maxRoll={5} intensity={0.01} />
      <Particles {...props} />
    </>
  )
}
